/* TODO: FIX ESLINT */
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useSpring, animated, config } from 'react-spring'
import { Element, Link as ScrollLink } from 'react-scroll'
import { Link } from 'gatsby'

import Brand from './Brand'
import BurgerMenu from './BurgerMenu'
import CollapseMenu from './CollapseMenu'
import SelectLanguage from './SelectLanguage'
import UserContext from 'context/user/UserContext'
import mq from 'styles/breakpoints'

const Navbar = ({ isNavbarOpen, setNavbarOpen }) => {
  const { t } = useTranslation('navbar')
  const { user } = useContext(UserContext)

  const barAnimation = useSpring({
    from: { transform: 'translate3d(0, -10rem, 0)' },
    transform: 'translate3d(0, 0, 0)',
    fontFamily: `Roboto`,
    fontWeight: 100,
  })

  const linkAnimation = useSpring({
    from: { transform: 'translate3d(0, 30px, 0)', opacity: 0 },
    to: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
    delay: 800,
    config: config.wobbly,
  })

  /* eslint-disable jsx-a11y/anchor-is-valid */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  return (
    <>
      <NavBar style={barAnimation}>
        <FlexContainer>
          <Brand />
          <NavLinks style={linkAnimation}>
            <SelectLanguage />
            {user ? (
              <>
                <Element>
                  <ScrollLink
                    to="PricingPlans"
                    spy={true}
                    smooth={true}
                    duration={600}
                    offset={-40}
                  >
                    {t('pricing')}
                  </ScrollLink>
                </Element>
                <div>
                  <Link to={process.env.GATSBY_SITE_ACCOUNT_PATH}>
                    {t('account')}
                  </Link>
                </div>
              </>
            ) : (
              <>
                <Element>
                  <ScrollLink
                    to="PricingPlans"
                    spy={true}
                    smooth={true}
                    duration={600}
                    offset={-40}
                  >
                    {t('pricing')}
                  </ScrollLink>
                </Element>
                <div>
                  <Link to={process.env.GATSBY_SITE_SIGN_IN_PATH}>
                    {t('signIn')}
                  </Link>
                </div>
              </>
            )}
          </NavLinks>
          <BurgerWrapper>
            <BurgerMenu
              isNavbarOpen={isNavbarOpen}
              setNavbarOpen={setNavbarOpen}
            />
          </BurgerWrapper>
        </FlexContainer>
      </NavBar>
      <CollapseMenu isNavbarOpen={isNavbarOpen} setNavbarOpen={setNavbarOpen} />
    </>
  )
}

Navbar.propTypes = {
  isNavbarOpen: PropTypes.bool.isRequired,
  setNavbarOpen: PropTypes.func.isRequired,
}

export default Navbar

const NavBar = styled(animated.nav)`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  color: #fff;
  z-index: 2;
  font-size: 1.4rem;
`

const FlexContainer = styled.div`
  max-width: 120rem;
  display: flex;
  margin: auto;
  padding: 0 2rem;
  justify-content: space-between;
  height: 5rem;
`

const NavLinks = styled(animated.ul)`
  justify-self: end;
  list-style-type: none;
  margin: auto 0;
  display: flex;

  & a {
    color: black;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0 0.75rem;
    transition: all 300ms linear 0s;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: hsl(230, 50%, 70%);
      border-bottom: 1px solid gray;
    }
  }

  ${mq['md']} {
    display: none;
  }
`

const BurgerWrapper = styled.div`
  margin: auto 0;

  @media (min-width: 768px) {
    display: none;
  }
`
