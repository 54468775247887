import { css } from '@emotion/react'

const GlobalStyles = css`
  body,
  html {
    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
  }
  html {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto;
    box-sizing: border-box;
  }
  * {
    margin: 0;
    padding: 0;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  .fade-out {
    opacity: 0;
    transition: opacity 0.9s;
  }

  .spin {
    -webkit-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear;
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
`
export default GlobalStyles
