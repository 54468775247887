import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Global } from '@emotion/react'
import GlobalStyles from 'styles/GlobalStyles'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Footer from 'components/Footer'

const IndexLayout = ({ children }) => {
  const PIPEDRIVE_UUIDS = Object.freeze({
    en: process.env.GATSBY_ENGLISH_CHATBOT_PIPEDRIVE_UUID,
  })

  const updatePipedrive = useEffect(() => {
    if (typeof window !== 'undefined') {
      window.pipedriveLeadboosterConfig = {
        base: 'leadbooster-chat.pipedrive.com',
        companyId: 7452276,
        playbookUuid: PIPEDRIVE_UUIDS['en'],
        version: 2,
      }
    }
    ;(function () {
      var w = window
      if (w.LeadBooster) {
        w.LeadBooster = {
          q: [],
          on: function (n, h) {
            this.q.push({ t: 'o', n: n, h: h })
          },
          trigger: function (n) {
            this.q.push({ t: 't', n: n })
          },
        }
      }
    })()
  }, [PIPEDRIVE_UUIDS])
  return (
    <>
      <Global styles={GlobalStyles} />
      <Helmet>
        <script
          src="https://leadbooster-chat.pipedrive.com/assets/loader.js"
          type="text/javascript"
          async
        ></script>
        <script type="text/javascript">{updatePipedrive}</script>
      </Helmet>
      <ToastContainer
        limit={1}
        position="bottom-left"
        autoClose={8000}
        hideProgressBar={true}
      />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default IndexLayout
