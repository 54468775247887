import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import mq from 'styles/breakpoints'

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #000000;
  width: 10rem;
  margin-right: 2rem;
  z-index: 1;
  font-size: 1rem;

  ${mq['md']} {
    padding-bottom: 1rem;
    padding-left: 1.5rem;
  }
`

const SelectLanguage = () => {
  const { i18n, ready } = useTranslation()
  const LANGUAGE_CODE_ENGLISH = 'en'
  const LANGUAGE_CODE_SPANISH = 'es'
  const LANGUAGE_ENGLISH_LABEL = 'English'
  const LANGUAGE_SPANISH_LABEL = 'Español'

  const options = [
    { value: LANGUAGE_CODE_ENGLISH, label: LANGUAGE_ENGLISH_LABEL },
    { value: LANGUAGE_CODE_SPANISH, label: LANGUAGE_SPANISH_LABEL },
  ]

  const initializeSelectedLanguageLabel = () => {
    try {
      return options.find((opt) => opt.value === selectedLanguage).label
    } catch (error) {
      return
    }
  }

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language)
  const [selectedLanguageLabel, setSelectedLanguageLabel] = useState(
    initializeSelectedLanguageLabel(),
  )

  // change the language
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage)
  }, [selectedLanguage, i18n])

  const handleChange = (lang) => {
    setSelectedLanguage(lang.value)
    setSelectedLanguageLabel(lang.label)
  }

  return (
    <SelectContainer>
      <Select
        value={selectedLanguage}
        placeholder={selectedLanguageLabel}
        isLoading={!ready}
        onChange={handleChange}
        options={options}
      />
    </SelectContainer>
  )
}

export default SelectLanguage
