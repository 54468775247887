import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import mq from 'styles/breakpoints'

const HomeLink = styled(Link)`
  align-self: center;
  color: rgb(1, 1, 1, 0);
  margin-top: 1em;
`

const BrandContainer = styled.div`
  color: black;
  h3 {
    margin-top: -1em;
    font-size: 1em;
  }
  ${mq['xl']} {
    h3 {
      font-size: 0.7em;
    }
  }
  @media (max-width: 940px) {
    h3 {
      font-size: 0.5em;
    }
  }
  @media (max-width: 800px) {
    h3 {
      font-size: 0.45em;
    }
  }
  @media (max-width: 800px) {
    h3 {
      font-size: 0.45em;
    }
  }
  @media (max-width: 768px) {
    h3 {
      font-size: 0.75em;
    }
  }
`

const Brand = () => {
  const { t } = useTranslation('brand')

  return (
    <BrandContainer>
      <HomeLink to="/">
        <StaticImage
          src="../../images/png/3Dmeet-logo-full.png"
          alt={t('altImageLogo')}
          layout="constrained"
          width={300}
        />
      </HomeLink>
      <h3>{t('subtitle')}</h3>
    </BrandContainer>
  )
}

export default Brand
