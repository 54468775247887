/* TODO: FIX ESLINT */
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import { Link } from 'gatsby'
import { Element, Link as ScrollLink } from 'react-scroll'

import UserContext from 'context/user/UserContext'
import SelectLanguage from './SelectLanguage'

const CollapseMenu = ({ isNavbarOpen, setNavbarOpen }) => {
  const { t } = useTranslation('menuCollapse')
  const { user } = useContext(UserContext)

  const { open } = useSpring({ open: isNavbarOpen ? 0 : 1 })

  if (isNavbarOpen === true) {
    /* eslint-disable jsx-a11y/anchor-is-valid */
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
      <CollapseWrapper
        style={{
          transform: open
            .interpolate({
              range: [0, 0.2, 0.3, 1],
              output: [0, -20, 0, -200],
            })
            .interpolate((openValue) => `translate3d(0, ${openValue}px, 0`),
        }}
      >
        <NavLinks>
          <li>
            <SelectLanguage />
            {user ? (
              <>
                <LinkWrapper>
                  <Element>
                    <ScrollLink
                      to="PricingPlans"
                      spy={true}
                      smooth={true}
                      duration={600}
                      offset={-40}
                    >
                      {t('pricing')}
                    </ScrollLink>
                  </Element>
                </LinkWrapper>
                <LinkWrapper>
                  <Link to={process.env.GATSBY_SITE_ACCOUNT_PATH}>
                    {t('account')}
                  </Link>
                </LinkWrapper>
              </>
            ) : (
              <>
                <LinkWrapper>
                  <Element>
                    <ScrollLink
                      to="PricingPlans"
                      spy={true}
                      smooth={true}
                      duration={600}
                      offset={-40}
                    >
                      {t('pricing')}
                    </ScrollLink>
                  </Element>
                </LinkWrapper>
                <LinkWrapper>
                  <Link to={process.env.GATSBY_SITE_SIGN_IN_PATH}>
                    {t('signIn')}
                  </Link>
                </LinkWrapper>
              </>
            )}
          </li>
        </NavLinks>
      </CollapseWrapper>
    )
  }
  return null
}

CollapseMenu.propTypes = {
  isNavbarOpen: PropTypes.bool.isRequired,
  setNavbarOpen: PropTypes.func.isRequired,
}

export default CollapseMenu

const CollapseWrapper = styled(animated.div)`
  position: absolute;
  top: 4.5rem;
  left: 0;
  right: 0;
  z-index: 2;

  @media (min-width: 768px) {
    display: none;
  }
`

const NavLinks = styled.ul`
  list-style-type: none;
  padding: 2rem 1rem 2rem 2rem;
  color: #fff;
  background: white;

  & li {
    transition: all 300ms linear 0s;
  }

  & a {
    font-size: 1.2rem;
    color: black;
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 1px solid transparent;
    margin: 0 1.5rem;
    transition: all 300ms linear 0s;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: gray;
      border-bottom: 1px solid gray;
    }
  }
`

const LinkWrapper = styled.div`
  margin-bottom: 1em;
`
