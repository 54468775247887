import React from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import mq from 'styles/breakpoints'

const Footer = () => {
  const { t, i18n } = useTranslation('footer')

  const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #000000;
    color: #ffffff;
    width: 100%;
    font-size: 1rem;
    font-family: arial;
    padding: 4rem 15%;

    ${mq['md']} {
      font-size: 0.8rem;
      border: none;
    }
  `

  const CopyrightTextContainer = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;

    ${mq['md']} {
      font-size: 0.7rem;
    }
  `

  const FlexContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;

    ${mq['md']} {
      flex-direction: column;
    }
  `

  const Legal = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      color: #ffffff;
      text-decoration: none;
      box-shadow: none;

      margin: 0em 1em;
      font-size: 0.7rem;

      ${mq['md']} {
        font-size: 0.7rem;
      }
    }

    ${mq['md']} {
      margin-top: 1.5em;
    }
  `

  return (
    <FooterContainer>
      <FlexContainer>
        <CopyrightTextContainer>
          © {new Date().getFullYear()} Virtual Immersive Educational Worlds,
          Inc. | {t('allRightsReserved')}
        </CopyrightTextContainer>
        <Legal>
          <Link to={`legal/${i18n.language}/terms-of-service`}>
            {t('termsOfService')}
          </Link>
          <Link to={`legal/${i18n.language}/privacy-policy`}>
            {t('privacyPolicy')}
          </Link>
          <Link to={`legal/${i18n.language}/code-of-conduct`}>
            {t('codeOfConduct')}
          </Link>
        </Legal>
      </FlexContainer>
    </FooterContainer>
  )
}

export default Footer
